<template>
  <intersect
    @enter="bannerSliderIntersect = 'Intersected'"
    @leave="bannerSliderIntersect = 'Not intersected'"
  >
    <section class="index-hero">
      <div class="container index-hero-slider-container">
        <div class="index-hero__slider swiper">
          <swiper
            ref="swiperRef"
            @slideChange="updateCurrentSlide"
            class="mySwiper"
            :options="swiperOption"
          >
            <swiper-slide v-for="banner in banners" :key="banner.id">
              <a
                :href="banner.attributes.link || 'https://magnum.kz'"
                class="index-hero__slide swiper-slide"
                :style="{ backgroundImage: `url(${getBannerImage(banner)})` }"
                @click.native="gtmSelectPromotion(banner)"
              >
                <a
                  class="index-hero__slide-link"
                  :href="banner.attributes.link || 'https://magnum.kz'"
                  >{{ $t("more") }}</a
                >
                <a target="_blank">
                  <div class="index-hero__content">
                    <div
                      :style="getTitleColor(banner)"
                      class="index-hero__title"
                    >
                      {{ banner.attributes.header }}
                    </div>
                    <div
                      :style="getTitleColor(banner)"
                      class="index-hero__subtitle"
                    >
                      {{ banner.attributes.sub_header }}
                    </div>
                  </div>
                </a>
              </a>
            </swiper-slide>
          </swiper>
          <div @click="prevSlide" class="swiper-button-prev"></div>
          <div @click="nextSlide" class="swiper-button-next"></div>
          <div class="slider-pagination-wrapper">
            <div class="pagination__custom"></div>
            <span class="swiper-counts"
              >{{ currentSlide }} / {{ slidesTotalCount }}</span
            >
          </div>
        </div>
      </div>
    </section>
  </intersect>
</template>

<script>
import { mapState } from "vuex";
import { getBanners } from "@/api";
import { eventBus } from "@/helpers";
import Intersect from "vue-intersect";

export default {
  name: "HomeBannerSlider",
  components: { Intersect },
  data() {
    return {
      banners: null,
      slidesTotalCount: 0,
      currentSlide: 1,
      bannerSliderIntersect: "",
      shownBanners: [],
      swiperOption: {
        lazy: true,
        slideTo: 1,
        slidesPerView: 1,
        spaceBetween: 20,
        initialSlide: 1,
        centeredSlides: false,
        loop: true,
        responsive: true,
        pagination: {
          el: ".pagination__custom",
          clickable: true,
          renderBullet: function (index, className) {
            return `<div class="wrapper ${className}">
              <div class="pie spinner"></div>
              <div class="pie filler"></div>
              <div class="mask"></div>
            </div>`;
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  watch: {
    selectedCity: {
      handler() {
        this.loadBanners();
      },
    },
    currentSlide: {
      handler() {
        if (
          this.banners &&
          this.bannerSliderIntersect === "Intersected" &&
          !this.shownBanners.includes(this.banners[this.currentSlide - 1].id)
        ) {
          {
            this.shownBanners.push(this.banners[this.currentSlide - 1].id);
            this.gtmViewPromotion(this.banners[this.currentSlide - 1]);
          }
        }
      },
    },
    bannerSliderIntersect: {
      handler() {
        if (
          this.banners &&
          this.bannerSliderIntersect === "Intersected" &&
          !this.shownBanners.includes(this.banners[this.currentSlide - 1].id)
        ) {
          if (!this.shownBanners.includes(this.banners[0].id)) {
            this.shownBanners.push(this.banners[0].id);
            this.gtmViewPromotion(this.banners[0]);
          } else {
            this.shownBanners.push(this.banners[this.currentSlide - 1].id);
            this.gtmViewPromotion(this.banners[this.currentSlide - 1]);
          }
        }
      },
    },
  },
  created() {
    this.loadBanners();
    if (this.$isMobile()) {
      this.swiperOption.slidesPerView = 1;
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.banners) {
        this.shownBanners.push(this.banners[0].id);
        this.gtmViewPromotion(this.banners[0]);
      }
    }, 1500);
  },

  methods: {
    loadBanners() {
      this.banners = null;
      getBanners().then((banners) => {
        this.banners = banners;
        this.slidesTotalCount = this.banners.length;
      });
    },
    gtmSelectPromotion(item) {
      eventBus.$emit("gtm", {
        event: "select_promotion",
        type: "select_promotion",
        items: [item],
        slot: "Главный слайдер",
        listName: this.fromListName,
        category: this.catalog ? "Каталог скидок" : "Наивысшая категория",
      });
    },
    gtmViewPromotion(banner) {
      eventBus.$emit("gtm", {
        event: "view_promotion",
        type: "view_promotion",
        slot: "Главный слайдер",
        items: [banner],
        listName: this.fromListName,
        category: this.catalog ? "Каталог скидок" : "Наивысшая категория",
      });
    },
    updateCurrentSlide() {
      this.currentSlide = this.swiper?.swiperInstance.realIndex + 1;
    },
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
    getBannerImage(banner) {
      const placeHolderImageUrl =
        "https://placeholder.pics/svg/288x380/B7FF7D/6C6D73-79E858/no%20moblie%20image";
      if (this.$isMobile()) {
        return banner.attributes.image_mob?.data?.attributes?.url
          ? this.$helpers.getAbsolutePath(
              banner.attributes.image_mob?.data?.attributes?.url
            )
          : placeHolderImageUrl;
      }
      return this.$helpers.getAbsolutePath(
        banner.attributes.image?.data?.attributes?.url
      );
    },
    getTitleColor(item) {
      return "color:" + item?.attributes?.left_side_text_color;
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-next,
.swiper-button-prev {
  left: auto;
  right: -33px;
}
.swiper {
  height: 550px;
  background-color: #f9f9f9;
  border-radius: 15px;
}

@media (max-width: 680px) {
  .swiper {
    height: auto;
  }
}

@media (max-width: 450px) {
  .swiper {
    height: 390px;
  }
}

@media (max-width: 360px) {
  .swiper {
    height: 380px;
  }
}
</style>
